class ModulePagination {
    constructor(list = [], handle, option = {
        paged: 1,
        page_per_view: 12,
    }) {
        if (!list || list.length === 0) return;
        if (!handle){
            handle = document.createElement('div');
            handle.classList.add('pagination-handle', 'mt-w60p');

            const parent = list[0].parentNode.parentNode;
            const nextSibling = list[0].parentNode.nextSibling

            parent.insertBefore(handle, nextSibling);
        }

        const totalPages = Math.ceil(list.length / option.page_per_view);
        if (totalPages <= 1){
            handle.style.display = "none";
        }

        this._list = list;
        this._handle = handle;
        this._paged = option.paged;
        this._page_per_view = option.page_per_view;
        
        this.init();
    }

    getList() {
        return this._list;
    }

    getHandle() {
        return this._handle;
    }

    getPaged() {
        return this._paged;
    }

    getPagePerView() {
        return this._page_per_view;
    }

    get() {
        return {
            list: this._list,
            handle: this._handle,
            paged: this._paged,
            page_per_view: this._page_per_view,
        };
    }

    init() {
        this.handlePagination();
        this.change();
    }


    handlePagination() {
        const { handle } = this.get();

        // Cập nhật HTML cho phân trang
        handle.innerHTML = this.pagination(this._paged, Math.ceil(this._list.length / this._page_per_view));

        // Lấy tất cả các liên kết trang
        const linkPages = handle.querySelectorAll('a[data-page]');
        
        // Gắn sự kiện click cho mỗi liên kết
        linkPages.forEach(link => {
            link.onclick = (e) => {
                e.preventDefault();
                const newPage = parseInt(link.dataset.page, 10);
                this.setPage(newPage);
            };
        });
    }

    setPage(newPage) {
        this._paged = newPage;
        this.change();
        this.handlePagination();
    }

    change() {
        const { list } = this.get();
        
        list.forEach((item, idx) => {
            const PageOfItem = Math.floor(idx / this._page_per_view) + 1;
            item.style.display = (PageOfItem === this._paged) ? "" : "none";
        });
    }

    
    pagination(currentPage, totalPages) {
        if (totalPages <= 1) return '';

        let paginationHTML = '';
        let display;
        let next = '<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.875L7 6.875L1 12.875" stroke="black" stroke-width="2"/></svg>';
        let prev = '<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 0.875L2 6.875L8 12.875" stroke="black" stroke-width="2"/></svg>';

        // Previous button
        if (currentPage > 1) {
            paginationHTML += `<a href="#" data-page="${currentPage - 1}" class="prev page-number">${prev}</a>`;
        }

        // First page
        if (currentPage > 2) {
            paginationHTML += `<a href="#" data-page="1" class="page-number">1</a>`;
        }

        // Ellipsis before current page range
        if (currentPage > 3) {
            paginationHTML += `<span class="dots page-number">...</span>`;
        }

        // Page numbers around the current page
        let startPage = Math.max(1, currentPage - 1);
        let endPage = Math.min(totalPages, currentPage + 1);

        for (let i = startPage; i <= endPage; i++) {
            display = i < 10 ? `${i}` : i;
            paginationHTML += (i === currentPage) 
                ? `<span class="active page-number">${display}</span>`
                : `<a href="#" data-page="${i}" class="page-number">${display}</a>`;
        }

        // Ellipsis after current page range
        if (currentPage < totalPages - 2) {
            paginationHTML += `<span class="dots page-number">...</span>`;
        }

        // Last page
        if (currentPage < totalPages - 1) {
            display = totalPages < 10 ? `${totalPages}` : totalPages;
            paginationHTML += `<a href="#" data-page="${totalPages}" class="page-number">${display}</a>`;
        }

        // Next button
        if (currentPage < totalPages) {
            paginationHTML += `<a href="#" data-page="${currentPage + 1}" class="next page-number">${next}</a>`;
        }

        return paginationHTML;
    }

    destroy() {
        // Xóa các sự kiện gắn trên các liên kết trong handle
        if (!this._handle){
            return;
        }
        const linkPages = this._handle.querySelectorAll('a[data-page]');
        linkPages.forEach(link => {
            link.onclick = null;
        });

        // Xóa các tham chiếu để giải phóng bộ nhớ
        this._list = null;
        this._handle = null;
    }
}

export default ModulePagination;