class ModuleCalcHeight {
    constructor () {
        this.objs = document.querySelectorAll('.calc-height');
        this.updateHeights();
        
        // Lắng nghe sự kiện resize một lần
        window.addEventListener('resize', () => this.updateHeights());

        // Tạo observer để theo dõi sự thay đổi DOM
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.addedNodes.length || mutation.removedNodes.length) {
                    // Cập nhật lại danh sách objs khi DOM thay đổi
                    this.objs = document.querySelectorAll('.calc-height');
                    this.updateHeights();
                }
            }
        });
        
        // Quan sát các thay đổi trong DOM
        observer.observe(document.body, { childList: true, subtree: true });
    }

    updateHeights() {
        this.objs.forEach((obj) => {
            const dataName = obj.getAttribute('data-name');
            document.body.style.setProperty(`--${dataName}`, `${obj.clientHeight}px`);
        });
    }
}


export default ModuleCalcHeight;