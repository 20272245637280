// Libs
// import jQuery from 'jquery'
// import flatpickr from 'flatpickr';
// import $ from 'jquery';
import Swal from 'sweetalert2';

import './../libs/select2/select2.min.js';
import './../libs/lottieplayer/lottie-player.js';

// Modules
// import ModuleFlatpickr from './modules/ModuleFlatpickr';
import ModuleSwiper from './modules/ModuleSwiper';
import ModuleTab from './modules/ModuleTab';
import ModuleFilterable from './modules/ModuleFilterable';
import ModuleAccordion from './modules/ModuleAccordion';
import ModuleToggle from './modules/ModuleToggle';

//
import header from './Sections/header.js';

// Handler
import PaginationHandler from './Handler/PaginationHandler';
import SVGImport from './modules/SVGImport.js';
import ModuleDatePicker from './modules/ModuleDatePicker.js';
import ModuleCalcHeight from './modules/ModuleCalcHeight.js';

import ModuleFancybox from './modules/ModuleFancybox';
import ModuleCopyToClipboard from './modules/ModuleCopyToClipboard';
import ModulePopup from './modules/ModulePopup';
import BackToTop from './modules/BackToTop';

const BraneyModulesManager = {
    runModule: function () {
        new ModuleSwiper();
        const tab = new ModuleTab(document.querySelector('.js-tabhead'), 'tab');
        const filter = new ModuleFilterable(document.querySelector('.js-tabfilter'), 'tab')
        new ModuleAccordion();
        new ModuleToggle();
        
        new ModuleFancybox();
        new PaginationHandler();
        new ModuleDatePicker();

        new header().init();

        // new Datepicker().init();
        new ModuleCopyToClipboard().init();
        new SVGImport().init();
        new ModulePopup();
        new ModuleCalcHeight();
        
        
        const backtotop = document.querySelectorAll('.jsbacktotop');

        backtotop.forEach(function(item){
            item.style.display = "none";

            handleScroll();
            window.addEventListener('scroll', handleScroll);

            function handleScroll(){
                if (window.scrollY > 200){
                    item.style.display = "";
                }else{
                    item.style.display = "none";
                }
            }
            
            item.addEventListener('click', function(e){
                e.preventDefault();
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            })
        })

        const formBanner = document.getElementById('bannerForm');
        if (!formBanner) return;

        formBanner.addEventListener('submit', function(event){
            event.preventDefault();
            const formData = new FormData(event.target);
            const values = Object.fromEntries(formData.entries());
            if (!values.checkInDate || !values.checkOutDate || values.adultsNumber < 0 || values.childrenNumber < 0 ){
                Swal.fire({
                    icon: "error",
                    title: "ERROR!",
                    text: "Please fill in all information fields."
                });
                return;
            }
            let url = 'https://book-directonline.com/properties/saigonprincedirect?';
            url += `checkInDate=${values.checkInDate}`;
            url += `&checkOutDate=${values.checkOutDate}`;
            url += `&items[0][adults]=${values.adultsNumber}`;
            url += `&items[0][children]=${values.childrenNumber}`;

            window.open(url, "_blank");
        })


    }
};

document.addEventListener('DOMContentLoaded', function () {
    BraneyModulesManager.runModule();


    // const observer = new MutationObserver(function (mutationsList, observer) {
    //     for (const mutation of mutationsList) {
    //         if (mutation.addedNodes.length) {
    //             mutation.addedNodes.forEach(node => {
    //                 if (node.nodeType === Node.ELEMENT_NODE) {
    //                     BraneyModulesManager.runModule();
    //                 }
    //             });
    //         }
    //     }
    // });
    // observer.observe(document.body, { childList: true, subtree: true });
})