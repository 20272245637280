class ModulePopup {
    constructor(){
        const popups = document.querySelectorAll('[data-popup]');
        popups.forEach((item)=> {
            const name = item.getAttribute('data-popup');
            if (name){
                const popupElement = document.querySelector(`.${name}`);
                if (!popupElement){
                    return;
                }

                item.addEventListener('click', e => {
                    e.preventDefault();
                    popupElement.classList.add('is-showed')
                    popupElement.classList.remove('hidden')
                })

                const closePopups = popupElement.querySelectorAll('.close');

                closePopups.forEach((closePopup) =>{
                    closePopup.addEventListener('click', (e)=>{
                        e.preventDefault();
    
                        popupElement.classList.add('hidden');
                        popupElement.classList.remove('is-showed');
                    })
                })
            }
        })
    }
}

export default ModulePopup; 