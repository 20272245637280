
export default function SVGImport(){
    this.init = () => {
        var imgs = document.querySelectorAll('img.svg');
        imgs.forEach(function(img) {
            // Load SVG file
            var src = img.getAttribute('data-lazysrc') || img.src;
            var xhr = new XMLHttpRequest();
            
            xhr.open('GET', src, true);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    // Parse SVG content
                    var parser = new DOMParser();
                    var svgContent = parser.parseFromString(xhr.responseText, 'image/svg+xml').documentElement;

                    // Append SVG content to the document
                    img.insertAdjacentElement('afterend', svgContent);

                    // Optional: Hide the <img> element
                    img.style.display = 'none';
                    img.remove();
                }
            };
            xhr.send();
        });
    }
}