import $ from 'jquery';
import ModulePagination from './ModulePagination';

class ModuleTab {
    _tabBtns;
    _tabSections;
    _tabSelect;
    _name;

    constructor(tabs, name = "tab", first = false) {
        if (!tabs) return false;

        const tabBtns = tabs.querySelectorAll(`.btn-${name}`);
        const tabSections = tabs.querySelectorAll(`.${name}-section`);
        const tabSelect = tabs.querySelector(`select.select-${name}`);
        const tabOptions = tabs.querySelectorAll(`select.select-${name} option`);
        const handle = tabs.querySelector('.pagination-handle');

        if (!tabBtns || tabBtns.length <= 0) { return }

        const handleActive = this.handleActive;
        this._name = name;
        this._tabs = tabs;
        this._tabBtns = tabBtns;
        this._tabSections = tabSections;
        this._tabSelect = tabSelect;
        this._tabOptions = tabOptions;
        this._handle = handle;

        let tabCategoryValue = tabs?.getAttribute('data-tab-default');

        if (first === true) {
            tabCategoryValue = tabBtns[0].dataset[name];
        }

        if (tabSelect){
            tabSelect.value = tabCategoryValue;
        }

        tabBtns.forEach(function (tab) {
            const tabValue = tab.dataset[name];

            if (tabCategoryValue && tabCategoryValue == tabValue) {
                handleActive(tabBtns, tabSections, handle, tabSelect, tabValue, 'active', name, false);
            }

            tab.addEventListener('click', (e) => {
                e.preventDefault();
                handleActive(tabBtns, tabSections, handle, tabSelect, tabValue, 'active', name, true);
            })
        })

        const $disabledResults = $(".select-tab");
        $disabledResults.select2({
            minimumResultsForSearch: -1
        });
        
        $disabledResults.on('select2:select', function (e) {
            const data = e.params.data;
            handleActive(tabBtns, tabSections, handle, tabSelect, data.id, 'active', name, false);
        });

        tabSelect && tabSelect.addEventListener('change', (e) => {
            handleActive(tabBtns, tabSections, handle, tabSelect, tabSelect.value, 'active', name, false);
        })
    }

    handleActive(tabBtns, tabSections, handle, tabSelect = null, valueActived, classActive = 'active', name, condition = false) {
        if (tabSelect){
            tabSelect.value = valueActived;
        }
        tabBtns.forEach((tabItem) => {
            const idxTabValue = tabItem.dataset[name];

            if (valueActived === idxTabValue) {
                tabItem.classList.add(classActive);

                if (tabItem.getAttribute('data-popup') && condition) {
                    // do something
                }
            } else {
                tabItem.classList.remove(classActive);
            }
        })
        tabSections.forEach((tabSection) => {
            const idxTabValue = tabSection.dataset[name];
            if (valueActived === idxTabValue) {
                tabSection.classList.add(classActive);
                tabSection.classList.remove('hidden');
                
                if ( handle ){
                    let per = parseInt(handle.dataset.per) || 12;
                    if (per > 0 ){
                        handle.style.display = "";
                        if (tabSections._modulePagination) {
                            tabSections._modulePagination.destroy();
                        }
                        const wrapper = tabSection.querySelectorAll('.item');
                        const option = {
                            paged: 1,
                            page_per_view: per,
                        }
                        tabSections._modulePagination = new ModulePagination(wrapper, handle, option);
                    } else{
                        handle.style.display = "none";
                    }
                }

            } else {
                tabSection.classList.remove(classActive);
                tabSection.classList.add('hidden');
            }
        })

        
    }

    activeTabValue(value, condition = false) {
        this.handleActive(this._tabBtns, this._tabSections, this._handle, this._tabSelect, value, 'active', this._name, condition);
    }
}

export default ModuleTab; 