import $ from 'jquery';
import ModulePagination from './ModulePagination';

class ModuleFilterable {
    _tabBtns;
    _tabSections;
    _tabSelect;
    _name;

    constructor(tabs, name = "tab", first = false) {
        if (!tabs) return false;

        const tabBtns = tabs.querySelectorAll(`.btn-${name}`);
        const tabFilters = tabs.querySelectorAll(`.${name}-filter`);
        const tabSelect = tabs.querySelector(`select.select-${name}`);
        const tabOptions = tabs.querySelectorAll(`select.select-${name} option`);
        const handle = tabs.querySelector(`.pagination-handle`);

        if (!tabBtns || tabBtns.length <= 0) { return }

        const handleActive = this.handleActive;
        this._name = name;
        this._tabBtns = tabBtns;
        this._tabFilters = tabFilters;
        this._tabSelect = tabSelect;
        this._tabOptions = tabOptions;
        this._handle = handle;

        let tabCategoryValue = tabs?.getAttribute('data-tab-default');

        if (first === true) {
            tabCategoryValue = tabBtns[0].dataset[name];
        }

        if (tabSelect){
            tabSelect.value = tabCategoryValue;
        }

        tabBtns.forEach(function (tab) {
            const tabValue = tab.dataset[name];

            if (tabCategoryValue && tabCategoryValue == tabValue) {
                handleActive(tabBtns, tabFilters, handle, tabSelect, tabValue, 'active', name, false);
            }

            tab.addEventListener('click', (e) => {
                e.preventDefault();
                handleActive(tabBtns, tabFilters, handle, tabSelect, tabValue, 'active', name, true);
            })
        })

        var $disabledResults = $(".select-tab");
        $disabledResults.select2({
            minimumResultsForSearch: -1
        });
        
        $disabledResults.on('select2:select', function (e) {
            var data = e.params.data;
            handleActive(tabBtns, tabFilters, handle, tabSelect, data.id, 'active', name, false);
        });
        tabSelect.addEventListener('change', (e) => {
            handleActive(tabBtns, tabFilters, handle, tabSelect, tabSelect.value, 'active', name, false);
        })
    }

    handleActive(tabBtns, tabFilters, handle, tabSelect = null, valueActived, classActive = 'active', name, condition = false) {
        if (tabSelect){
            tabSelect.value = valueActived;
        }
        tabBtns.forEach((tabItem) => {
            const idxTabValue = tabItem.dataset[name];

            if (valueActived === idxTabValue) {
                tabItem.classList.add(classActive);

                if (tabItem.getAttribute('data-popup') && condition) {
                    // do something
                }
            } else {
                tabItem.classList.remove(classActive);
            }
        })

        let listPage = [];
        
        tabFilters.forEach((item)=> {
            if (valueActived === 'all'){
                item.style.display = "";
                listPage.push(item);
                return;
            }

            const valueTab = item.dataset.tab.split(',');
            valueTab.forEach(function(value){
                if (value === valueActived){
                    item.style.display = "";
                    listPage.push(item);
                }else{
                    item.style.display = "none";
                }
            })
        })

        if ( handle ){
            let per = parseInt(handle.dataset.per) || 12;
            if (per > 0 ){
                handle.style.display = "";
                if (tabFilters._modulePagination) {
                    tabFilters._modulePagination.destroy();
                }
                const option = {
                    paged: 1,
                    page_per_view: per,
                }
                tabFilters._modulePagination = new ModulePagination(listPage, handle, option);
            } else{
                handle.style.display = "none";
            }
        }
    }

    activeTabValue(value, condition = false) {
        this.handleActive(this._tabBtns, this._tabFilters, this._handle, this._tabSelect, value, 'active', this._name, condition);
    }
}

export default ModuleFilterable; 