import Swiper from "swiper";
import {
    Parallax,
    Navigation,
    Autoplay
} from 'swiper/modules';

class ModuleToggle {

    constructor() {
        const offers = document.querySelectorAll('.js-offer');
        offers.forEach((offer) => {
            let section = offer.querySelector('.offer-section');
            const handles = offer.querySelectorAll('.offer-handle');

            let swOffer = new Swiper();
            swOffer.destroy();


            handles.forEach((handle) => {
                if (section.classList.contains('showed')) {
                    handle.classList.add('clicked');
                } else {
                    handle.classList.remove('clicked');
                }

                handle.onclick = (e) => {
                    e.preventDefault();
                    section.classList.toggle('showed');

                    if (section.classList.contains('showed')) {
                        swOffer = new Swiper(section.querySelector('.swiper'), {
                            modules: [Navigation, Autoplay, Parallax],
                            parallax: true,
                            touchRatio: 0.8,
                            slidesPerView: 'auto',
                            simulateTouch: true,
                            centeredSlides: true,
                            loop: true,

                            autoplay: {
                                delay: 3200,
                            },

                            navigation: {
                                nextEl: '[data-swiper="slideSpecialOffers"] .swiper-button-next',
                                prevEl: '[data-swiper="slideSpecialOffers"] .swiper-button-prev',
                            },

                            on: {
                                init: function () {
                                    if (!this.allowSlideNext && !this.allowSlidePrev) {
                                        this.allowTouchMove = false
                                    }
                                }
                            }
                        });
                    } else {
                        swOffer.destroy();
                    }
                    handles.forEach(function (tmp) {
                        if (section.classList.contains('showed')) {
                            tmp.classList.add('clicked');
                        } else {
                            tmp.classList.remove('clicked');
                        }
                    })
                }
            })
        })
    }
}

export default ModuleToggle;