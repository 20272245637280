/**
 * Slider
 */
import Swiper from 'swiper';
import { 
    Parallax, 
    Navigation, 
    Pagination, 
    Autoplay} from 'swiper/modules';

class ModuleSwiper{
    defaults = {
        selectors: {
            'slider': '.swiper',
        },
        options: {
            slideSimpleMobile: {
                modules: [Navigation, Autoplay, Parallax],
                parallax: true,
                touchRatio: 0.8,

                slidesPerView: 1,
                spaceBetween: 16,
                
                autoplay: {
                    delay: 3500,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    480: {
                        slidesPerView: 1.2,
                    },
                    640: {
                        slidesPerView: 0,
                    }
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                on: {
                    init: function(){
                        if (!this.allowSlideNext && !this.allowSlidePrev){
                            this.allowTouchMove = false
                        }
                    }
                }
            },

            slideRelated: {
                modules: [Navigation, Autoplay, Parallax],
                parallax: true,
                touchRatio: 0.8,
                slidesPerView: 1,
                spaceBetween: 42,
                simulateTouch: true,

                autoplay: {
                    delay: 3500,
                },

                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 10,

                    }, 
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 20,

                    }, 
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,

                    }, 
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 20,

                    },
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 42,

                    }
                },

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                on: {
                    init: function(){
                        if (!this.allowSlideNext && !this.allowSlidePrev){
                            this.allowTouchMove = false
                        }
                    }
                }
            },

            slideHeroFraction: {
                modules: [Pagination, Navigation, Parallax, Autoplay],
                parallax: true,
                touchRatio: 0.8,
    
                pagination: {
                    el: '.swiper-pagination',
                    type: 'custom',

                    renderCustom: function (swiper, current, total) {
                        return current + '<span class="flash">/</span>' + total; 
                    }
                },
    
                spaceBetween: 20,
                slidesPerView: 'auto',
                grabCursor: true,
    
                // slideToClickedSlide: true,,
                touchMove: false,
                // loop: true,
    
                speed: 1200,
                autoplay: {
                    delay: 3500,
                    disableOnInteraction: false
                },
    
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                on: {
                    init: function(){
                        if (!this.allowSlideNext && !this.allowSlidePrev){
                            this.allowTouchMove = false
                        }
                    }
                }
            },

            slideBanner: {
                modules: [Pagination, Parallax, Autoplay],
                parallax: true,
                touchRatio: 0.8,
    
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    dynamicBullets: false,
                    clickable: true,
                },
    
                spaceBetween: 0,
                slidesPerView: 'auto',
                grabCursor: true,
    
                // slideToClickedSlide: true,,
                touchMove: false,
                // loop: true,
    
                speed: 1200,
                autoplay: {
                    delay: 3800,
                    disableOnInteraction: false
                },

                on: {
                    init: function(){
                        if (!this.allowSlideNext && !this.allowSlidePrev){
                            this.allowTouchMove = false
                        }
                    }
                }
            },

            slideHero: {
                modules: [Pagination, Navigation, Parallax, Autoplay],
                parallax: true,
                touchRatio: 0.8,
    
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    dynamicBullets: false,
                    clickable: true,
                },
    
                spaceBetween: 10,
                slidesPerView: 'auto',
                grabCursor: true,
    
                // slideToClickedSlide: true,,
                touchMove: false,
                // loop: true,
    
                speed: 1200,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
    
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                on: {
                    init: function(){
                        if (!this.allowSlideNext && !this.allowSlidePrev){
                            this.allowTouchMove = false
                        }
                    }
                }
            },
            
            slideLogos: {
                modules: [Autoplay],
                spaceBetween: 40,
                grabCursor: true,
                a11y: false,
                freeMode: true,
                speed: 9000,
                loop: true,
                slidesPerView: "auto",
    
                autoplay: {
                    delay: 0.5,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    0: {
                        spaceBetween: 30,
                    },
                    480: {
                        spaceBetween: 30,
                    },
                    768: {
                        spaceBetween: 40,
                    },
                    1024: {
                        spaceBetween: 100,
                    }
                },
            },

            slideRelatedCol: {
                modules: [Navigation, Autoplay, Parallax],

                parallax: true,
                touchRatio: 0.8,

                slidesPerView: 1,
                spaceBetween: 16,
                
                autoplay: {
                    delay: 3500,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    480: {
                        slidesPerView: 1.2,
                        spaceBetween: 16,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    }
                },

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                on: {
                    init: function(){
                        if (!this.allowSlideNext && !this.allowSlidePrev){
                            this.allowTouchMove = false
                        }
                    }
                }
            },
        }
    }

    constructor(){
        this.slider();
    }

    handleDevices(sw, {itemSlider, option}, maxWidth = 1920){
        sw.destroy();

        const handleSlide = () => {
            if (window.innerWidth < maxWidth ){
                if (sw.destroyed == true){
                    sw = new Swiper(itemSlider, option);
                }
            }else{
                if (!sw.destroyed && sw.initialized){
                    sw.destroy();
                }
            }
        }

        handleSlide();
        window.addEventListener('resize', handleSlide);
    }

    slider() {
        const {selectors, options} = this.defaults;

        const handleDevies = this.handleDevices;
        
        const slideSelectors = document.querySelectorAll(selectors.slider);
        slideSelectors.forEach(function(itemSlider){
            if (itemSlider.classList.contains('swiper-initialized') || itemSlider.dataset.slide === "slideSpecialOffers"){
                return false;
            }
            var option = options[itemSlider.dataset.slide] ?? {};

            // let optionTemp = JSON.parse(JSON.stringify(option));
            // if (optionTemp.navigation){
            //     if (optionTemp.navigation.prevEl === '.swiper-button-prev'){
            //         optionTemp.navigation.prevEl = itemSlider.querySelector('.swiper-button-prev');
            //     }
            //     if (optionTemp.navigation.nextEl === '.swiper-button-next'){
            //         optionTemp.navigation.nextEl = itemSlider.querySelector('.swiper-button-next');
            //     }
            // }

            itemSlider.style.display = "";
            var sw = new Swiper(itemSlider, option);

            if (itemSlider.dataset.slide === "slideSimpleMobile"){
                handleDevies(sw, {itemSlider, option}, 640)
            }
            else if (itemSlider.dataset.slide === "slideRelatedCol"){
                handleDevies(sw, {itemSlider, option}, 1024)
            }

            // sw.disable();
            // const observeSwiper = new IntersectionObserver((entries) => {
            //     entries.forEach((entry, index) => {
            //         if (entry.isIntersecting){
            //             sw.enable();
            //             sw.update();
            //             sw.autoplay && sw.autoplay.start();
            //         }else{
            //             sw.autoplay && sw.autoplay.pause();
            //             sw.disable();
            //         }
            //     })
            // })

            // observeSwiper.observe(itemSlider);
        });
    }
}

export default ModuleSwiper;