import ModulePagination from "../modules/ModulePagination";

class PaginationHandler{
    constructor(){
        const jspages = document.querySelectorAll('.js-pagination');
        jspages.forEach((jsItem)=> {
            const wrapper = jsItem.querySelectorAll('.pagination-wrapper>*');
            const handle = jsItem.querySelector('.pagination-handle');

            if (handle){
                let per = parseInt(handle.dataset.per) || 12;
                if (per > 0 ){
                    handle.style.display = "";
                    if (jsItem._modulePagination) {
                        jsItem._modulePagination.destroy();
                    }
                    const option = {
                        paged: 1,
                        page_per_view: per,
                    }
                    jsItem._modulePagination = new ModulePagination(wrapper, handle, option);
                }else{
                    handle.style.display = "none";
                }
            }
            // else{
            //     if (jsItem._modulePagination) {
            //         jsItem._modulePagination.destroy();
            //     }
    
            //     const option = {
            //         paged: 1,
            //         page_per_view: 12,
            //     }
            //     jsItem._modulePagination = new ModulePagination(wrapper, handle, option);
            // }
        })
    }
}

export default PaginationHandler;