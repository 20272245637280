class ModuleDatePicker{
    constructor(){
        $(".input-date").datepicker({
            minDate: new Date(),
            dateFormat: 'yy-mm-dd',
        });

        const inpNumbers = document.querySelectorAll('.input-number');
        inpNumbers.forEach((item) => {
            const parent = item.parentElement;
            const minus = parent.querySelector('.minus');
            const plus = parent.querySelector('.plus');

            const minItem = parseInt(item.getAttribute('min')) || undefined;
            const maxItem = parseInt(item.getAttribute('max')) || undefined;

            minus.onclick = (e) => {
                let value = parseInt(item.value);
                if (minItem === undefined || value > minItem){
                    item.value = value - 1;
                }
            }
            plus.onclick = (e) => {
                let value = parseInt(item.value);
                if (maxItem === undefined || value < maxItem ){
                    item.value = value + 1;
                }
            }
        })
    }
}

export default ModuleDatePicker;