export default function header(){
    this.init = function(){
        this.handleToggleNav();
        this.handlePopup();
        this.handleHeader();
    }

    this.handleToggleNav = () => {
        const nav = document.querySelector('.header-navbar-mb');
        const toggle = document.querySelector('.toggle-nav');
        if (!nav || !toggle){ return;}

        toggle.addEventListener('click', (e)=> {
            e.preventDefault();
            nav.classList.add('showed-flex');
        })
        const closesNav = nav.querySelectorAll('.close');
        closesNav.forEach((close) => {
            close.addEventListener('click', (e)=> {
                e.preventDefault();
                closeFn();
            })
        })

        function closeFn(){
            nav.classList.remove('showed-flex');
        }
    }

    this.handlePopup = () => {
        const popupBook = document.querySelector('.popup-book');
        const handlePopup = document.querySelectorAll('[data-popup="popup-book"]');

        if (!popupBook) return;

        handlePopup.forEach(function(item){
            item.addEventListener('click', function(e){
                e.preventDefault();

                popupBook.classList.add('showed-flex');
            })
        })

        const closesPopup = popupBook.querySelectorAll('.close');
        closesPopup.forEach((close) => {
            close.addEventListener('click', (e)=> {
                e.preventDefault();
                popupBook.classList.remove('showed-flex');
            })
        })
    }

    this.handleHeader = () => {
        const header = document.querySelector('header');
        const hHeader = header.clientHeight;
        
        handleScroll();
        window.addEventListener('scroll', handleScroll);

        function handleScroll(){
            if (window.scrollY > hHeader){
                header.classList.add('scrolling');
            }else{
                header.classList.remove('scrolling')
            }
        }
    }
}