class ModuleAccordion {
    constructor(){
        const accordions = document.querySelectorAll('.js-accordion');
        accordions.forEach((accordion) => {
            let items = accordion.querySelectorAll('.accordion');
            
            items.forEach((item) => {
                const handles = item.querySelectorAll('.accordion-handle');
                handles.forEach((handle)=> {
                    handle.onclick = (e)=> {
                        e.preventDefault();

                        items.forEach((tmp)=> {
                            if (tmp === item ) return;
                            tmp.classList.remove('showed');                            
                        })
                        item.classList.toggle('showed');
                    }
                })
            })
        })
    }
}

export default ModuleAccordion;