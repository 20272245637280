import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

class ModuleFancybox {
  constructor(){  
    let ls = document.querySelectorAll('a[data-fancybox]');
    let arrayFancy = [];
    ls.forEach(function(item){
      if (!arrayFancy.includes(item.getAttribute('data-fancybox'))){
        arrayFancy.push(item.getAttribute('data-fancybox'));
      }
    })
  
    arrayFancy.forEach(function(item){
      Fancybox.bind(`[data-fancybox="${item}"]`, {
          Thumbs: {
            // Carousel: {
            //   fill: false,
            //   center: true,
            // },
            type: "classic",
          },
  
          // Images: {
          //   initialSize: "cover",
          // },
    
          // Image: {
          //   zoom: false,
          // },
  
          youtube: {
            controls: 1,
            showinfo: 0
          },
  
          mobile: {
            clickSlide: false
          },
  
          clickContent: false
      });
    })
  }
}

export default ModuleFancybox;